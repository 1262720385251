<template>
  <div>
    <div class="header">
      <div class="personal"
        style="padding-top: 20px">
        <div class="boxTop">
          <div style="padding: 10px; text-align: left">
            <div class="biankuang1"></div>
            <span style="font-size: 18px; font-weight: bold">信息申报</span>
          </div>
          <!-- <div>
        <div>
          <span style="color:red;">*</span> 社区(村)
        </div>
        <div>
          <input v-model="dataform.OrgName"
            type="text"
            placeholder="请选择社区(村)"
            @click="orgCodePicker = true"
            readonly="readonly"
            required
            clickable />
        </div>
      </div> -->
          <div class="boxCenter">
            <van-cell-group>
              <van-field v-model="dataform.OrgName"
                label="社区(村)"
                placeholder="请选择社区(村)"
                @click="orgCodePicker = true"
                readonly="readonly"
                required
                clickable />
              <van-popup v-model="orgCodePicker"
                round
                position="bottom">
                <van-cascader v-model="cascaderValue"
                  title="请选择所在地区"
                  :options="orgonList"
                  @close="orgCodePicker = false"
                  :field-names="{
                    text: 'OName',
                    value: 'OCode',
                    children: 'Children',
                  }"
                  @finish="onFinish" />
              </van-popup>
              <!-- <van-field v-model="dataform.RName"
          label="小区(组)"
          placeholder="小区(组)"
          @click="idListRId = true"
          readonly="readonly"
          required
          clickable />
        <van-popup v-model="idListRId"
          position="bottom"
          get-container="body">
          <van-picker show-toolbar
            :columns="ridlist"
            value-key="Title"
            @cancel="idListRId = false"
            @confirm="onidListRId">
          </van-picker>
        </van-popup> -->
              <!-- <van-field v-model="dataform.Name"
          required
          center
          label="老人姓名"
          placeholder="请输入老人姓名"
          :rules="[{ required: true, message: '请输入老人姓名' }]">
          <template #button>
            <van-uploader :after-read="afterReadBaidu">
              <van-icon name="scan"
                size="35" />
            </van-uploader>
          </template>
        </van-field> -->
              <van-field v-model="dataform.Name"
                required
                center
                label="老人姓名"
                placeholder="请输入老人姓名"
                :rules="[{ required: true, message: '请输入老人姓名' }]">
              </van-field>
              <van-field v-model="dataform.IDCard"
                required
                label="身份证号"
                placeholder="请输入身份证号"
                :rules="[{ required: true, message: '请输入身份证号' }]" />
              <!-- @blur="IdNum(dataform.IDCard)" -->
              <!-- <van-field name="Sex"
          required
          label="性别">
          <template #input>
            <van-radio-group v-model="dataform.Sex"
              direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="dataform.Birthday"
          required
          name="RelieveTime"
          label="出生日期"
          @click="birthdayData = true"
          readonly="readonly"
          placeholder="请选择出生日期" />
        <van-popup v-model="birthdayData"
          position="bottom"
          get-container="body">
          <van-datetime-picker show-toolbar
            type="date"
            v-model="birthdayDataNy"
            title="请选择出生日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="birthdayData = false"
            @confirm="onbirthdayData">
          </van-datetime-picker>
        </van-popup> -->
              <!-- <van-field v-model="dataform.Nation"
          required
          label="民族"
          placeholder="请输入民族" /> -->
              <!-- <van-field v-model="dataform.NationName"
          label="民族"
          placeholder="请选择所属民族"
          @click="minzuPicker = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="minzuPicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="minzu"
            value-key="Name"
            @confirm="onrecumenANation"
            @cancel="minzuPicker = false" />
        </van-popup> -->
              <!-- <van-field v-model="dataform.RegAddr"
          required
          label="户籍地址"
          type="textarea"
          rows="1"
          autosize
          placeholder="请输入户籍地址" /> -->
              <van-field v-model="dataform.Address"
                required
                label="现住址"
                type="textarea"
                rows="1"
                autosize
                placeholder="请输入现住址">
                <!-- <template #button>
            <van-button size="mini"
              type="primary"
              @click="writeAddress()">同步户籍地址</van-button>
          </template> -->
              </van-field>

              <!-- <van-field v-model="dataform.TouchMan"
          required
          label="联系人姓名"
          placeholder="请输入联系人姓名" /> -->
              <van-field v-model="dataform.TouchTel"
                required
                label="联系电话"
                placeholder="请输入联系电话" />

              <van-field name="Demand"
                label="养老需求"
                required>
                <template #input>
                  <van-checkbox-group v-model="dataform.Demand"
                    direction="horizontal">
                    <!-- <van-checkbox name="助餐"
                shape="square"
                style="margin: 5px">助餐</van-checkbox> -->
                    <van-checkbox :name="2"
                      shape="square"
                      style="margin: 5px">助洁</van-checkbox>
                    <!-- <van-checkbox name="助行"
                shape="square"
                style="margin: 5px">助行</van-checkbox>
              <van-checkbox name="助浴"
                shape="square"
                style="margin: 5px">助浴</van-checkbox>
              <van-checkbox name="助医"
                shape="square"
                style="margin: 5px">助医</van-checkbox>
              <van-checkbox name="康复"
                shape="square"
                style="margin: 5px">康复</van-checkbox> -->
                    <van-checkbox :name="7"
                      shape="square"
                      style="margin: 5px">巡访关爱</van-checkbox>
                  </van-checkbox-group>
                </template>
              </van-field>

              <!-- <div class="submit">
          <span style="color: red; font-size: 14px;letter-spacing:8px">*</span>身份证照、生活照、(如有残疾、低保、特困类证件照)照片上传
          <span style="color: red;font-size: 12px;">! 照片大小不得超过6MB</span>
        </div>
        <van-uploader v-model="fileList"
          :multiple="true"
          class="imgs"
          :max-count="9"
          :after-read="afterRead"
          :before-delete="deleteImgs"
          :deletable="true"
          :max-size="6000 * 1024"
          @oversize="onOversize" /> -->
              <!-- <div v-if="imgShow">
          <div style="font-size: 12px; margin: 5px 10px">
            已上传照片(<span style="color: red">如果新增照片,需将已上传照片重新上传</span>)
          </div>
          <div v-for="item in imgArr"
            :key="item.index"
            style="
              display: inline-block;
              margin: 0px 10px;
              width: 80px;
              height: 80px;
            ">
            <img style="width: 100%; height: 100%"
              :src="item"
              @click="imgClick(dataform.HeadImgUrl)" />
          </div>
        </div> -->
              <div class="submit">附件</div>
              <van-uploader v-model="fileListPoor"
                multiple
                capture="camera"
                accept="*"
                class="imgs"
                :max-count="9"
                :after-read="afterReadPoor"
                :before-delete="deletePoor"
                @oversize="onOversize" />
              <div class="submit">备注</div>
              <van-field v-model="dataform.Remark"
                type="textarea"
                rows="3"
                style="background: #fafafa;margin-bottom:20px;"
                autosize
                placeholder="请输入备注信息" />
              <van-button round
                block
                type="info"
                color="#617bfa"
                style="font-size: 16px; margin: 10px auto; width: 90%"
                :loading="saveLoading"
                loading-text="保存中..."
                @click="SaveZh()">保存</van-button>
            </van-cell-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Compressor from "compressorjs";

import {
  WeGetOldManPage,
  DeleteOldMan,
  WeSaveOldManApply,
  GetChildOrgansNullToken,
  GetCodeEntrysList,
} from "@/api/oldman";
import { WeGetRegionList } from "@/api/yeguang";
import { getoldmanToken, setoldmanToken } from "@/utils/auth";
import { WeGetOrganTree } from "@/api/Organ";
import config from "@/config";
import axios from "axios";
import Vue from "vue";
import { ImagePreview } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(ImagePreview);
export default {
  data () {
    return {
      listdata: {
        accToken: getoldmanToken(), //token
        page: 1,
        limit: 100,
      },
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2050, 10, 1),
      birthdayDataNy: new Date(2021, 0, 17),
      listList: {},
      cascaderValue: "",
      count: "",
      orgonList: [],
      ridlist: [],
      // 登记
      dataform: {
        Name: "",
        OrgName: "",
        OrgCode: "",
        // RId: "",
        // RName: "",
        // Nation: "",
        IDCard: "",
        RegAddr: "",
        Address: "",
        // HeadImg: "",
        Diseases: "",
        Demand: [],
        TouchTel: "",
        // TouchMan: "",
        Remark: "",
        Annex: "",
      },
      minzu: "",
      djshow: "1",
      orgCodeShow: true, //社区(村)村庄
      birthdayData: false, //出生日期
      juzhuPicker: false, //疾病状况
      shengyuPicker: false, //计划生育特殊家庭
      orgCodePicker: false, //社区街道
      minzuPicker: false, //民族
      idListRId: false, //小区
      shReason: false,
      noticeShow: false,
      saveShow: false,
      saveLoading: false,
      uploadImgs: [],
      uploadImgsPoor: [],
      fileList: [],
      fileListPoor: [],
      access_token: "",
      imgShow: false,
      Annex: [],
    };
  },
  created () {
    this.zidianlist();
    // this.baiduToken()
    // if (this.$route.params.id != 0 && this.$route.params.id != undefined) {
    //   this.getDetail();
    //   this.orgCodeShow = false
    // }
    this.getOrgonTree();
  },
  methods: {
    getDetail () {
      console.log(this.$route.params.id);
      this.dataform = JSON.parse(this.$route.params.id);
      console.log(this.dataform);
      WeGetOldManPage(this.dataform).then((res) => {
        if (res.data.code == 0) {
          this.dataform = res.data.data;
          if (this.dataform.AuditState == 1) {
            Toast.fail("信息待审核");
            this.shReason = true;
            this.saveShow = true;
            this.noticeShow = false;
          } else if (this.dataform.AuditState == 2) {
            Toast.fail("信息已审核");
            this.shReason = false;
            this.noticeShow = false;
          } else if (this.dataform.AuditState == 9) {
            Toast.fail("信息已审核未通过");
            this.shReason = true;
            this.saveShow = true;
            this.noticeShow = true;
          }
          this.dataform.Sex = this.dataform.Sex + "";
          this.dataform.Marriage = this.dataform.Marriage + "";
          this.dataform.BodyState = this.dataform.BodyState + "";
          this.dataform.Lonely = this.dataform.Lonely + "";
          this.dataform.PoorMan = this.dataform.PoorMan + "";
          this.dataform.ExtremelyPoor = this.dataform.ExtremelyPoor + "";
          this.dataform.Paralysis = this.dataform.Paralysis + "";
          this.dataform.StayBehind = this.dataform.StayBehind + "";
          this.dataform.EmptyNest = this.dataform.EmptyNest + "";
          this.dataform.AdvancedAge = this.dataform.AdvancedAge + "";
          this.dataform.Deformity = this.dataform.Deformity + "";
          this.dataform.OtherMan = this.dataform.OtherMan + "";
          this.dataform.Demand = this.dataform.Demand.split(",");
          // 时间转格式
          this.dataform.Birthday = this.dataform.Birthday.slice(0, 10);
          // 图片转格式
          this.imgArr = this.dataform.HeadImgUrl.split(",");
          this.imgShow = true;
          if (this.dataform.PoorImgUrl != "") {
            this.imgsArr = this.dataform.PoorImgUrl.split(",");
            this.imgsShow = true;
          }
        } else if (res.data.code == 1) {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 点击预览图片
    imgClick (row) {
      var imgArr = row.split(",");
      ImagePreview(imgArr);
    },
    onOversize () {
      Toast("文件大小不能超过 6M");
    },
    // 同步户籍地址
    writeAddress () {
      if (this.dataform.RegAddr != "") {
        this.dataform.Address = this.dataform.RegAddr;
      } else {
        Toast.fail("请输入户籍地址!");
      }
    },
    baiduToken () {
      axios({
        method: "post",
        url:
          this.$host +
          "/oauth/2.0/token?grant_type=client_credentials&client_id=l75tR92b7eirQA3BPD0YBTLP&client_secret=9Z88glkBuIxD7LQz4xQY6KzK9znc027Z",
        // url: '//aip.baidubce.com/oauth/2.0/token?grant_type=client_credentials&client_id=l75tR92b7eirQA3BPD0YBTLP&client_secret=9Z88glkBuIxD7LQz4xQY6KzK9znc027Z',
        // data: filterData,
      }).then((res) => {
        console.log(res.data.access_token);
        this.access_token = res.data.access_token;
      });
    },
    // 字典查询
    zidianlist: function () {
      // 民族
      GetCodeEntrysList({ kind: "3" }).then((res) => {
        this.minzu = res.data.data;
      });
    },
    onrecumenANation (val) {
      //民族
      this.dataform.Nation = val.Coding;
      this.dataform.NationName = val.Name;
      this.minzuPicker = false;
    },
    // 地区选择完成后 触发事件
    onFinish ({ selectedOptions }) {
      this.orgCodePicker = false;
      this.fieldValue = selectedOptions.map((option) => option.OName).join("/");
      this.OCode = selectedOptions.map((option) => option.OCode).join("/");
      this.dataform.OrgName = this.fieldValue;
      this.dataform.OrgCode = this.OCode;
      this.ridlist = [];
      this.dataform.RId = "";
      this.dataform.RName = "";
      this.ridpush(this.dataform.OrgCode);
    },
    // 社区
    getOrgonTree () {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data[0].Children;
      });
    },
    // 小区
    ridpush: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.dataform.RId = val.RId;
      this.dataform.RName = val.Title;
      this.idListRId = false;
    },
    //出生日期
    onbirthdayData (val) {
      this.dataform.Birthday = this.dateformat(val);
      this.birthdayData = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },

    // 身份证识别
    afterReadBaidu (file, fileList) {
      var that = this;
      if (file.file.size > 1500000) {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: "正在识别...",
          forbidClick: true,
        });
        new Compressor(file.file, {
          quality: 0.6,
          success (result) {
            var formData = new FormData();
            formData.append("file", result, result.name);
            formData.append(
              "access_token",
              "24.94ffa59d62dd6ee6d3ac23213e59df98.2592000.1637914886.282335-24647933"
            );
            formData.append("image", file.content.split(",")[1]);
            formData.append("id_card_side", "front");
            let filterData = {
              access_token: that.access_token,
              image: file.content.split(",")[1],
              id_card_side: "front",
            };

            axios({
              url: "https://aip.baidubce.com/rest/2.0/ocr/v1/idcard",
              method: "post",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: formData,
            })
              .then((res) => {
                if (res.data.idcard_number_type == 1) {
                  Toast.clear();
                  Toast.success("识别成功");
                  that.dataform.Name = res.data.words_result["姓名"].words;
                  that.dataform.IDCard =
                    res.data.words_result["公民身份号码"].words;
                  that.dataform.RegAddr = res.data.words_result["住址"].words;
                  that.IdNum(res.data.words_result["公民身份号码"].words);
                } else {
                  Toast.clear();
                  Toast.fail("识别失败");
                }
              })
              .catch((ce) => {
                console.log(ce);
              });
          },
        });
      } else {
        var fileData = new FormData();
        fileData.append(
          "access_token",
          "24.94ffa59d62dd6ee6d3ac23213e59df98.2592000.1637914886.282335-24647933"
        );
        fileData.append("image", file.content.split(",")[1]);
        fileData.append("id_card_side", "front");
        let filterData = {
          access_token: that.access_token,
          image: file.content.split(",")[1],
          id_card_side: "front",
        };
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: "正在识别...",
          forbidClick: true,
        });
        axios({
          url: "https://aip.baidubce.com/rest/2.0/ocr/v1/idcard",
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: fileData,
        })
          .then((res) => {
            if (res.data.idcard_number_type == 1) {
              Toast.success("识别成功");
              Toast.clear();
              that.dataform.Name = res.data.words_result["姓名"].words;
              that.dataform.IDCard =
                res.data.words_result["公民身份号码"].words;
              that.dataform.RegAddr = res.data.words_result["住址"].words;
              that.IdNum(res.data.words_result["公民身份号码"].words);
            } else {
              Toast.clear();
              Toast.fail("识别失败");
            }
          })
          .catch((ce) => {
            console.log(ce);
            Toast.success(ce);
            Toast.success(res.data);
            console.log(res.data);
          });
      }
    },
    async fileImgCompressor (files) {
      files.forEach(function (file, i) {
        // if (file.size > 400000) {
        return new Promise(function (resolve, reject) {
          new Compressor(file.file, {
            quality: 0.5,
            success (result) {
              files[i] = result;
              console.log(result);
              resolve(result);
            },
          });
        });
        // }
      });
      return files;
    },
    // 证件照 残疾、低保、特困类证件照
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      // 大于2.5MB的jpeg和png图片都缩小像素上传
      if (file.file.size > 2500000) {
        new Compressor(file.file, {
          quality: 0.6,
          success (result) {
            var formData = new FormData();
            let c = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            //构造一个 FormData，把后台需要发送的参数添加
            formData.append("file", result, result.name);
            axios
              .post(config.apiUploadUrl, formData, c)
              .then((res) => {
                if (res.data.code === 0) {
                  that.uploadImgs = that.uploadImgs.concat(res.data.data);
                  that.dataform.HeadImg = that.uploadImgs;
                  file.status = "";
                  file.message = "";
                } else {
                  Toast.fail(res.data.msg);
                }
              })
              .catch(() => {
                Toast.fail("图片上传失败");
                file.status = "failed";
                file.message = "上传失败";
              });
          },
        });
      } else {
        var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        if (Array.isArray(file)) {
          file.forEach((item, index) => {
            formData.append("file", item.file);
          });
        } else {
          formData.append("file", file.file);
        }
        let c = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        // 添加请求头
        axios
          .post(config.apiUploadUrl, formData, c)
          .then((res) => {
            if (res.data.code === 0) {
              this.uploadImgs = this.uploadImgs.concat(res.data.data);
              this.dataform.HeadImg = this.uploadImgs;
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          })
          .catch(() => {
            Toast.fail("图片上传失败");
            file.status = "failed";
            file.message = "上传失败";
          });
      }
    },
    // 删除证件照
    deleteImgs (file, fileList) {
      this.dataform.HeadImg.splice(fileList.index, 1);
      this.fileList.splice(fileList.index, 1);
      // this.fileList = [];
      console.log(this.fileList);
      // this.uploadImgs = [];
      // this.dataform.HeadImg = [];
    },
    // 附件
    afterReadPoor (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      // 大于2.5MB的jpeg和png图片都缩小像素上传

      var formData = new FormData();
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file);
      axios
        .post('https://zkxcylapi.anluoyun.cn/api/UploadFile/UploadFiles', formData, c)
        .then((res) => {
          if (res.data.code === 0) {
            // this.uploadImgs = this.uploadImgsPoor.concat(res.data.data);
            this.Annex.push(res.data.data);
            file.status = "";
            file.message = "";
          } else {
            Toast.fail(res.data.msg);
          }
        })
        .catch(() => {
          Toast.fail("上传失败");
          file.status = "failed";
          file.message = "上传失败";
        });
    },
    deletePoor (file, fileList) {
      // this.fileListPoor = [];
      // this.uploadImgsPoor = [];
      // this.dataform.PoorImg = [];
      this.Annex.splice(fileList.index, 1);
      this.fileListPoor.splice(fileList.index, 1);
    },
    // 获取身份证
    IdNum (value) {
      console.log(value);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value.length > 0) {
        if (reg.test(value)) {
          this.go(value.length);
        } else {
          Toast.fail("身份证号码不正确");
        }
      }
    },
    go (val) {
      var iden =
        this.dataform.IDCard == ""
          ? this.dataform.IDCard
          : this.dataform.IDCard;
      console.log(iden);
      var id = iden.substring(0, 6);
      this.dataform.NativeCantonCode = id;
      console.log(this.dataform.NativeCantonCode);
      var sex = null;
      var birth = null;
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = 0;
      if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth =
          iden.substring(6, 10) +
          "-" +
          iden.substring(10, 12) +
          "-" +
          iden.substring(12, 14);
        if (
          iden.substring(10, 12) < month ||
          (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
        )
          age++;
      }
      if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth =
          "19" +
          iden.substring(6, 8) +
          "-" +
          iden.substring(8, 10) +
          "-" +
          iden.substring(10, 12);
        if (
          iden.substring(8, 10) < month ||
          (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
        )
          age++;
      }
      if (sex % 2 == 0) {
        sex = 2;
        this.dataform.Sex = sex + "";
        // this.dataform.Sex = sex;
        console.log(this.dataform.Sex);
        this.age = age;
        this.dataform.Birthday = birth;
        this.birthdayDataNy = new Date(this.dataform.Birthday);
      } else {
        sex = 1;
        // this.dataform.Sex = sex;
        this.dataform.Sex = sex + "";
        console.log(this.dataform.Sex);
        this.age = age;
        this.dataform.Birthday = birth;
        this.birthdayDataNy = new Date(this.dataform.Birthday);
      }
    },
    SaveZh () {
      // window.close();
      // this.$router.push("/ylOrder/declare/declareList?openid=" + this.$route.query["openid"]);

      var regPos = /^[\u4e00-\u9fa5]{0,}$/;
      var idcode = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (
        this.dataform.OrgCode == "" ||
        this.dataform.OrgCode == null ||
        this.dataform.OrgCode == undefined
      ) {
        Toast.fail("请选择社区");
      } else if (
        this.dataform.Name == "" ||
        this.dataform.Name == null ||
        this.dataform.Name == undefined
      ) {
        Toast.fail("请输入老人姓名");
      } else if (regPos.test(this.dataform.Name) != true) {
        Toast.fail("请输入正确的老人姓名");
      } else if (idcode.test(this.dataform.IDCard) != true) {
        Toast.fail("请选择正确身份证号");
      } else if (
        this.dataform.IDCard == "" ||
        this.dataform.IDCard == null ||
        this.dataform.IDCard == undefined
      ) {
        Toast.fail("请输入身份证号");
      } else if (
        this.dataform.Address == "" ||
        this.dataform.Address == null ||
        this.dataform.Address == undefined
      ) {
        Toast.fail("请输入居住地址");
      } else if (
        this.dataform.TouchTel == "" ||
        this.dataform.TouchTel == null ||
        this.dataform.TouchTel == undefined
      ) {
        Toast.fail("请输入联系电话");
      } else if (
        this.dataform.Demand == "" ||
        this.dataform.Demand == null ||
        this.dataform.Demand == undefined ||
        this.dataform.Demand.length == 0
      ) {
        Toast.fail("请选择养老需求");
      } else {
        this.dataform.Demand = this.dataform.Demand + "";
        this.dataform.Annex = this.Annex + "";
        this.dataform.OpenID = this.$route.query["openid"];
        (this.saveLoading = true),
          axios({
            method: "POST",
            url: 'https://zkxcylapi.anluoyun.cn/api/OldmanApply/WeSaveOldManApply',
            data: {
              OpenID: this.dataform.OpenID,
              OrgCode: this.dataform.OrgCode,
              Name: this.dataform.Name,
              IDCard: this.dataform.IDCard,
              Address: this.dataform.Address,
              TouchTel: this.dataform.TouchTel,
              Remark: this.dataform.Remark,
              Demand: this.dataform.Demand,
              Annex: this.dataform.Annex,
            },
          }).then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              Toast.success("保存成功!");
              this.saveLoading = false;
              // this.$router.replace({ name: "declareList" });
              window.close();
              this.$router.push(
                "/ylOrder/declare/declareList?openid=" +
                this.$route.query["openid"]
              );
            } else {
              Toast.fail(res.data.msg);
              this.saveLoading = false;
            }
          });
        // WeSaveOldManApply(this.dataform)
        //   .then((res) => {
        //     console.log(res);
        //     if (res.data.code == 0) {
        //       Toast.success("保存成功!");
        //       this.saveLoading = false;
        //       // this.$router.replace({ name: "declareList" });
        //       window.close();
        //       this.$router.push(
        //         "/ylOrder/declare/declareList?openid=" +
        //         this.$route.query["openid"]
        //       );
        //     } else {
        //       Toast.fail(res.data.msg);
        //       this.saveLoading = false;
        //     }
        //   })
        //   .catch((_) => {
        //     setTimeout(() => {
        //       this.saveLoading = false;
        //     }, 1000);
        //   });
      }
    },
  },
};
</script>
<style scoped>
.header {
  /* position: relative;
  height: 120px; */
  /* margin-bottom: 80px; */
  /* background-image: url("https://api.qxzhqm.cn//upload/images/2022/5/185a1721-af0.png");
  background-size: 100% 40%; */
  background-repeat: no-repeat;
  position: absolute;
  /* background: linear-gradient(#f63e3b, #eee); */
  background: #0f97ff;
  width: 100%;
  height: 180px;
}
.personal {
  width: 100%;
  align-items: center;
  text-align: center;
  /* background-color: #eee; */
  /* position: absolute;
  top: 10px; */
}
.biankuang1 {
  border: 2px solid #0f97ff;
  width: 0;
  height: 18px;
  float: left;
  border-radius: 2px;
  background: #0f97ff;
  margin-right: 10px;
}
.title-class {
  min-width: 30% !important;
}
.value-class {
  min-width: 50% !important;
}
.submit {
  position: relative;
  font-size: 17px;
  color: #646566;
  line-height: 24px;
  padding: 10px 30px 10px 15px;
}
.imgs {
  margin: 10px;
}
.van-cell {
  font-size: 17px;
  padding: 13px 16px;
}
.boxTop {
  width: 95%;
  margin: auto;
  border-radius: 8px;
  background: white;
}
.boxCenter {
  padding: 0 10px;
  text-align: left;
}
</style>